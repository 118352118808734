<template>
  <div class="grid panel-demo">
    <div class="col-4">
      <div class="card">
        <h5>Contacts</h5>
        <ul
          class="p-orderlist-list"
          role="listbox"
          aria-multiselectable="multiple"
          style="height: 100%"
        >
          <li
            class="p-orderlist-item"
            :class="{ pactive: conv['id'] == uid }"
            v-for="conv in conversations"
            :key="conv['id']"
            v-on:click="selectContact(conv['id'])"
          >
            <div>{{ conv["name"] }}</div>
            <Badge
              v-if="conv['name'] in notif"
              :value="notif[conv['name']].length"
              severity="danger"
            ></Badge>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-8">
      <div class="chat" id="chatjump">
        <div class="chat__header" v-if="uid == -1">
          <span class="chat__header__greetings">
            <!--Welcome {{ userData["name"] }}!-->
            Select a contact on the left to chat. Find more contacts
            <router-link :to="{ name: 'search' }">here.</router-link>
          </span>
        </div>

        <div v-if="uid != -1" class="chat__header">
          <!-- v-if="msgData.length == 0 && uid != -1">-->
          <span class="chat__header__greetings">
            <!--Welcome {{ userData["name"] }}!-->
            You're chatting with
            <router-link
              v-if="!otherUserData['coach']"
              :to="{
                name: 'userprofile',
                params: { uid: otherUserData['id'] },
              }"
              target="_blank"
            >
              {{ otherUserData["name"] }}.
            </router-link>
            <router-link
              v-else
              :to="{
                name: 'coachprofile',
                params: { uid: otherUserData['id'] },
              }"
              target="_blank"
            >
              {{ otherUserData["name"] }}.
            </router-link>
          </span>
          <Button v-if="!iscoach" icon="pi pi-lock" label="Allow coach to view objectives" class="p-button-rounded p-button-text thinbtn" @click="allowObjectives(otherUserData['id'])" />
        </div>

        <chat-list :msgs="msgData"></chat-list>
        <chat-form ref="chatform" @submitMessage="sendMessage" v-if="uid != -1"></chat-form>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from "../service/UserService";
import ChatList from "@/components/C_ChatList";
import ChatForm from "@/components/C_ChatForm";
export default {
  props: {
    uid: {
      default: -1,
    },
  },
  data() {
    return {
      iscoach: false,
      userData: {},
      otherUserData: {
        id: -1,
      },
      msgData: [],
      conversations: [],
      notif: {},
      timeoutHandle: null,
    };
  },
  components: {
    ChatList,
    ChatForm,
  },
  userService: null,
  created() {
    this.userService = new UserService();
    this.userService.getUser(-1).then((data) => {
      this.userData = data;
      this.iscoach = data['coach'];
    });
    this.userService.getConversations().then((data) => {
      this.conversations = data;
    });

    this.reload(this.uid);

    // Repeatedly check for new messages.
    this.repeatCheck();
  },
  watch: {
    "$route.params.uid": function (uid) {
      this.reload(uid);
    },
  },
  methods: {
    repeatCheck() {
      this.timeoutHandle = setTimeout(() => {
        this.checkNewMessages();
        this.repeatCheck();
      }, 5000);
    },
    checkNewMessages() {
      this.userService.getNewMessages().then((data) => {
        this.notif = {};
        for (var i in data) {
          var row = data[i];
          if (!(row["name1"] in this.notif)) {
            this.notif[row["name1"]] = [];
          }
          this.notif[row["name1"]].push(row);
          this.reload(row["uid1"], false, true);
        }
      });
    },
    messageExists(payload) {
      for (var i in this.msgData) {
        if (this.msgData[i].msg == payload.msg && this.msgData[i].from.ts == payload.from.ts && this.msgData[i].from.id == payload.from.id) {
          console.log(this.msgData[i], payload);
          return true;
        }
      }
      return false;
    },
    reload(uid, check = true, shownotif = false) {
      this.userService.getUser(uid).then((data) => {
        this.otherUserData = data;
      });
      this.userService.getMessages(uid).then((data) => {
        console.log(data.length);
        if (!this.iscoach && data.length == 0) {
          this.$refs.chatform.msg = "Hello, I saw you profile as a coach and I’d be interested into further exchanging for potentially setting a coaching program and discuss pricing.";
        }

        // this.msgData = new Set();
        for (var i in data) {
          var x = data[i];
          var msg = x["msg"];
          var payload = {
            from: {
              name: x["name1"],
              id: x["uid1"],
              isOther: x["uid1"] == uid,
              avatar: "/images/" + x["uid1"] + ".jpg",
              ts: x["ts"],
            },
            msg,
          };

          if (this.messageExists(payload)) {
            console.log("Exists");
            console.log(payload);
            continue;
          }

          this.msgData.push(payload);
          console.log(this.msgData.length);
          if (shownotif) {
            (async () => {
              try {
                const permission = await Notification.requestPermission();
                console.log(permission);
                const options = {
                  body: msg,
                  icon: "images/coachera-logo-sm.png"
                };
                const n = new Notification("New message by " + x["name1"], options);
              } catch (error) {
                console.log(error);
              }
            })();
          }
        }
      });
      if (check) {
        this.checkNewMessages();
      }
    },
    sendMessage(msg) {
      const username = this.userData.name;
      const avatar = "/images/" + this.userData.id + ".jpg";
      this.userService.addMessage(this.uid, msg).then((data) => {
        console.log(data);
        this.reload(this.uid);
      });

      setTimeout(() => {
        const element = document.getElementById("chat__body");
        element.scrollTop = element.scrollHeight;
      }, 0);
    },
    selectContact(uid) {
      this.msgData = [];
      this.userService.saveConversation({ uid: uid }).then((data) => {
        this.$router.push({ name: "conversations", params: { uid: uid } });
        setTimeout(() => {
          document.getElementById("chatjump").scrollIntoView();
          window.scrollBy(0, -100);
        }, 100);
      });
    },
    allowObjectives(id_) {
      this.userService.allowObjectives(id_).then((data) => {
        console.log(data);
        this.$toast.add({severity: 'info', summary: 'Success', detail: 'Now allowing coach to view objectives.', life: 5000});
      });
    }
  },
  unmounted() {
    clearTimeout(this.timeoutHandle);
  },
};
</script>

<style scoped>
.chat {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.chat__header {
  background: #ffffff;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
  border-radius: 24px 24px 24px 24px;
  padding: 1.8rem;
  font-size: 16px;
  font-weight: 700;
}
.chat__header__greetings {
  color: #292929;
}
.p-orderlist-item {
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pactive {
  background: #f3f3f3;
}

@media (max-width: 450px) {
  .col-4,
  .col-8 {
    width: 100%;
  }
}

.thinbtn {
  padding: 0rem 1rem;
  margin-left: 2rem;
}
</style>
<style lang="scss">
@media (max-width: 450px) {
  .layout-wrapper .layout-main-container {
    padding-left: 1rem !important;
  }
  .layout-main-container {
    padding: 7rem 1rem 2rem 1rem !important;
  }
}
</style>
