<template>
  <div>
    <div v-show="!sameDate">
      <Divider align="center">
        {{ timestampToDate(msg.from.ts) }}
      </Divider>
    </div>
    <div
      v-if="!msg.from.isOther"
      class="chat__mymessage"
      :class="[isSame ? '' : 'chat__first']"
    >
      <p
        :style="{ color: foregroundColor, 'background-color': backgroundColor }"
        class="chat__mymessage__paragraph"
      >
        {{ msg.msg }}
        <br />
        <span
          ><small :style="{ color: '#e9e9e9' }">{{ timestampToTime(msg.from.ts) }}</small></span
        >
      </p>
    </div>
    <div
      v-else
      class="chat__yourmessage"
      :class="[isSame ? '' : 'chat__first']"
    >
      <div class="chat__yourmessage__avartar">
        <img
          v-if="!isSame"
          :src="avatar"
          alt=""
          onerror="javascript:this.src='images/coachera-logo-sm.png'"
          class="chat__yourmessage__img"
        />
      </div>
      <div>
        <p v-if="!isSame" class="chat__yourmessage__user">
          <router-link
            v-if="iscoach"
            :to="{ name: 'userprofile', params: { uid: msg.from.id } }"
            target="_blank"
          >
            {{ msg.from.name }}
          </router-link>
          <router-link
            v-else
            :to="{ name: 'coachprofile', params: { uid: msg.from.id } }"
            target="_blank"
          >
            {{ msg.from.name }}
          </router-link>
        </p>
        <div class="chat__yourmessage__p">
          <p class="chat__yourmessage__paragraph">
            {{ msg.msg }}
            <br />
            <span
              ><small>{{ timestampToTime(msg.from.ts) }}</small></span
            >
          </p>
          <!-- <p class="chat__yourmessage__time">23:38</p> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from "../service/UserService";

export default {
  props: ["msg", "prev"],
  data() {
    return {
      sameDate: false,
      isSame: false,
      avatar: "", //require("../assets/avatar.svg"),
      iscoach: null,
    };
  },
  userService: null,
  computed: {
    foregroundColor() {
      return this.userService.getColors()[0];
    },
    backgroundColor() {
      return this.userService.getColors()[1];
    },
  },
  created() {
    this.userService = new UserService();
    this.isSame = this.isSamePerson(this.msg, this.prev);
    this.sameDate = this.isSameDate(this.msg, this.prev);
    if (this.msg?.from.avatar) {
      this.avatar = this.msg?.from.avatar;
    }
    this.userService.getUser(-1).then((data) => {
      this.iscoach = data["coach"];
    });
  },
  methods: {
    timestampToTime(ts) {
      var t = new Date(ts * 1000);
      return t.toLocaleTimeString();
    },
    timestampToDate(ts) {
      var d = new Date(ts * 1000);
      return d.toLocaleDateString();
    },
    isSamePerson(msg, prev) {
      if (prev === null) {
        return false;
      } else if (prev[0]?.from.name == msg?.from.name) {
        return true;
      } else {
        return false;
      }
    },
    isSameDate(msg, prev) {
      if (prev === null) {
        return false;
      } else if (
        this.timestampToDate(prev[0]?.from.ts) ==
        this.timestampToDate(msg?.from.ts)
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style>
.chat__mymessage {
  display: flex;
  justify-content: right;
  align-items: flex-end;
  margin: 0;
  min-height: 40px;
  line-break: anywhere;
}
.chat__mymessage__paragraph {
  margin: 0.4rem 0 0 1rem;
  border-radius: 20px 20px 0px 20px;
  max-width: 580px;
  /* background-color: #bbc4ef; */
  /* background-color: #ab81eb; */
  /* background-color: #1520a6; */
  background-color: #3944bc;
  color: #ffffff;
  padding: 0.8rem;
  font-size: 14px;
}
.chat__first {
  margin-top: 1rem;
}
.chat__yourmessage {
  display: flex;
}
.chat__yourmessage__avartar {
  width: 40px;
  margin-right: 1rem;
}
.chat__yourmessage__img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}
.chat__yourmessage__user {
  font-size: 14px;
  font-weight: 700;
  color: #292929;
  margin-top: 0;
  margin-block-end: 0rem;
}
.chat__yourmessage__p {
  display: flex;
  align-items: flex-end;
  line-break: anywhere;
}
.chat__yourmessage__paragraph {
  margin: 0.4rem 1rem 0 0;
  border-radius: 0px 20px 20px 20px;
  background-color: #f3f3f3;
  max-width: 180px;
  color: #414141;
  padding: 0.8rem;
  font-size: 14px;
}
.chat__yourmessage__time {
  margin: 0;
  font-size: 12px;
  color: #9c9c9c;
}
small {
  color: gray;
  font-size: x-small;
}
.p-divider-content {
  background-color: #FAFAFA!important;
}
</style>
