<template>
  <div class="chat__body" id="chat__body">
    <chat-message
      v-for="(msg, index) in msgs"
      :key="index"
      :msg="msg"
      :prev="[index == 0 ? null : msgs[index - 1]]"
    >
    </chat-message>
  </div>
</template>

<script>
import ChatMessage from "@/components/C_ChatMessage";
export default {
  components: {
    ChatMessage,
  },
  props: ["msgs"],
};
</script>

<style>
.chat__body {
  padding: 2rem;
  overflow: scroll;
  scroll-behavior: smooth;
  max-height: 70vh;
}
.chat__body::-webkit-scrollbar {
  display: none;
}
</style>
<style scoped>
@media screen and (min-width: 960px) {
  .col-8 {
    position: relative;
  }
  #chatjump {
    position: absolute;
    bottom: 0px;
    top: 0px;
    width: 100%;
  }
  .grid {
    position: absolute;
    width: 100%;
    bottom: 10px;
    top: 0px;
  }
  .layout-main {
    position: relative;
  }
}
</style>
